import React from 'react'

const Contact = ({title, url}) => {

    return(
        <div className="contact">
          <a href={url} title={title} target="_blank">{title}</a>
        </div>
      )

}
export default Contact